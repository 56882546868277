import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { Container, Row, Col } from 'reactstrap';
import Layout from '../../components/layout';
import { HTMLContent } from '../../components/content';

// This is exported separately so that it can be used by the Netlify CMS preview
// system which can't render the whole layout. If an optional "contentComponent"
// is provided we use it to render to blog post content. If not, we assume that
// the content has been provided as a string of pre-rendered HTML and default to
// the "HTMLContent" component.
export const BlogPostTemplate = ({
  content,
  contentComponent,
  timeToRead,
  author,
  title,
  date,
}) => {
  const PostContent = contentComponent || HTMLContent;

  return (
    <Container itemScope itemType="http://schema.org/BlogPosting">
      <Row>
        <Col>
          <div>
            <div className="blog-post-metadata">
              <span className="blog-post-author" itemProp="author" itemScope itemType="http://schema.org/Person">
                <span itemProp="name">By {author}</span>
              </span>
              <div className="blog-post-info">
                <span itemProp="dateCreated datePublished">{date}</span>
                <span className="blog-divider" />
                <span>{timeToRead} min read</span>
              </div>
            </div>
          </div>
          <h1 itemProp="name headline title" className="blog-post-title">{title}</h1>
          <PostContent content={content} itemProp="articleBody" className="blog-post-content" />
        </Col>
      </Row>
    </Container>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  contentComponent: PropTypes.elementType,
  timeToRead: PropTypes.number,
  author: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
};

BlogPostTemplate.defaultProps = {
  contentComponent: null,
  timeToRead: null,
  author: null,
  title: null,
  date: null,
};

// Blog post page component. This is the default export and is used by Gatsby at
// build time to generate a static page for each blog post, using the template
// component defined above.
const BlogPost = ({ data }) => (
  <Layout>
    <Helmet
      bodyAttributes={{ class: 'blog-post' }}
      title={data.post.frontmatter.title}
    />
    <BlogPostTemplate
      content={data.post.html}
      author={data.post.frontmatter.author.frontmatter.name}
      date={data.post.frontmatter.date}
      title={data.post.frontmatter.title}
      timeToRead={data.post.timeToRead}
    />
  </Layout>
);

BlogPost.propTypes = {
  data: PropTypes.shape({
    post: PropTypes.object.isRequired,
  }).isRequired,
};

export default BlogPost;

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    post: markdownRemark(
      fields: {
        slug: {
          eq: $slug
        }
      }
    ) {
      html
      timeToRead
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        author {
          frontmatter {
            name
          }
        }
      }
    }
  }
`;
