import React from 'react';
import PropTypes from 'prop-types';

// This file defines two components for rendering content. This allows us to
// support both pre-rendered HTML content (such as that provided by Gatsby at
// build time) and React components (such as that provided by the Netlify CMS
// preview app).
//
// It would be possible to combine these into a single component which performs
// type checking at runtime but that would be much less efficient.
export const HTMLContent = ({ content, ...rest }) => (
  <div
    dangerouslySetInnerHTML={{ __html: content }} // eslint-disable-line react/no-danger
    {...rest}
  />
);

export const Content = ({ content, ...rest }) => (
  <div {...rest}>{content}</div>
);

Content.propTypes = {
  content: PropTypes.node,
};

Content.defaultProps = {
  content: null,
};

HTMLContent.propTypes = Content.propTypes;
HTMLContent.defaultProps = Content.defaultProps;
